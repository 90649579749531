var Bacon = require('../../vendor/Bacon');
var Module = require('../eventDispatcher/Module');
var Sequencer = require('./Sequencer');
var Collection = require('./Collection');
var settings = require('./settings');
var utils = require('./utils');
var async = require('async');


var SongController = new Module( function() {
  var self = this;

  // self.ctl;  // set by app
  self.songs = utils.getSongs();
  self.sequencer = Sequencer;
  self.$canvas = $('canvas#pc-canvas');
  self.$input = $('input#pc-input');
  self.loadedSongs = {};



  // EVENTS ================

  self.on('player:track', function(song) {


  });



  self.on('player:action', function(event) {
    for (var i = 0; i < event.actions.length; i++) {
      switch (event.actions[i]) {

        case 'css-add':
          self.cssAdd(event, self.songs[event.song]);
          break;
        case 'css-remove':
          self.cssRemove(event, self.songs[event.song]);
          break;
        case 'fx':
          self.fxApply(event, self.songs[event.song]);
          break;
        case 'fx':
          self.fxRemove(event, self.songs[event.song]);
          break;
      }
    }
  });

  self.setCtl = function(ctl) {
    self.ctl = ctl;
  };





  // FX API ====================

  self.cssAdd = function(event, song) {
    if (!self.$canvas.length) {
      self.$canvas = $('canvas#pc-canvas');
    }
    var s = event.data.addBase;
    if (event.data.addBase.indexOf('fx-a') > -1) {
       s += '-' + song.tempo;
    }
    if (event.data.addTime) {
      s += '-' + event.data.addTime;
    }
    self.$canvas.addClass(s);
  };

  self.cssRemove = function(event, song) {
    var s = event.data.removeBase;
    if (event.data.removeBase.indexOf('fx-a') > -1) {
       s += '-' + song.tempo;
    }
    if (event.data.removeTime) {
      s += '-' + event.data.removeTime;
    }
    self.$canvas.removeClass(s);
  };


  self.fxApply = function(event, song) {
    self.emit('stagefx', event.data);
  };

  self.fxRemove = function(event, song) {
    self.emit('stagefx', event.data);
  };



  // IMAGES LOADING =====================================

  self.on('postcard-app:init', function() {
    self.loadImages();
  });


  // detect if we switched tracks
  self.on('player:track', function(song) {
    if (!self.loadedSongs[parseInt(song.number)] || !self.loadedSongs[parseInt(song.number)].loaded){
      self.$input.prop('placeholder', 'loading...');
      self.loadedSongs[parseInt(song.number)] = { waiting: true };
      console.log('set waiting');

      self.nextSong = self.songs[parseInt(song.number) - 1];
    } else {
      // already loaded
      self.nextSong = self.songs[parseInt(song.number) - 1];
      self.ctl.stageNewCanvas(self.nextSong);
    }

    // sett if this song is loaded

    // set new canvas
    // self.ctl.updatePreviewString(song.number +'. '+ song.title);
    // self.ctl.addString(song.number +'. '+ song.title);
  });

  self.loadImages = function() {
    return async.eachSeries(self.songs, function(song, callback) {
      return song.collection = new Collection(song.collection, function() {
        // have we asked for it already ?
        if (!self.loadedSongs[song.index + 1]) {
          self.loadedSongs[song.index + 1] = { loaded: true };

          // are we waiting for this one?
        } else if (self.loadedSongs[song.index + 1].waiting) {
          self.queueNextSong();
          self.$input.prop('placeholder', 'Start typing to begin');
        }
        song.imagesLoaded = true;
        return callback(null);
      });

    }, function(err) {
      if (err) {
        console.log('loadImages ERROR', err);
      } else {
        // console.log('all songs loaded');
      }
    });
  };

  self.queueNextSong = function() {
    if (self.nextSong) {
      self.ctl.stageNewCanvas(self.nextSong);
    }
  };

});

module.exports = SongController;
