var Stamp = require('./Stamp');
var _ = require('lodash');
var PIXI = window.PIXI;
// var PIXI = require('../../vendor/pixi');


function Mold(src, props) {
  var self = this;

  self.src = src;
  self.texture = PIXI.Texture.fromImage(src);
  self.props = props;

}
//
Mold.prototype.newStamp = function(ctx, str, yD, props, pos) {
  var self = this;

  // merge the Mold props with the Stamp instance props
  props = _.assign(props || {}, self.props);

  return new Stamp(self, ctx, str, yD, props, pos);
}

module.exports = Mold;
