var BezierEasing = require('bezier-easing');
var Bacon = require('../../vendor/Bacon');
var Module = require('../eventDispatcher/Module');
var PostcardController = require('./PostcardController');
var PIXI = window.PIXI;



var FxController = new Module( function() {
  var self = this;

  self.ctl = PostcardController;
  self.windowHeight = window.innerHeight;
  self.windowWidth = window.innerWidth;
  self.visible = false;
  self.working = false;
  self.transitionOut = false;
  self.interStageI = 0;
  self.interStageScale = [];
  self.interStageScaleI = 0;
  self.interStageRotation = [];
  self.interStageRotationI = 0;
  self.interStageAlpha = [];
  self.interStageAlphaI = 0;



  self.on('stagefx', function(e) {
    console.log('stagefx', 'self.working', self.working, 'e:', e, 'transitionOut', self.transitionOut, 'interStageI', self.interStageI);
    if (self.working) { return; }

    // toggle on/off
    if (e.toggle) {
      self.clearFxElms();
      self.initFxElms();
      self.showFxElms();
    } else if (e.toggle === false) {
      self.transitionOut = true;
    }

    self.stageTransitions(e);
  });



  self.initFxElms = function(){
    // self.ctx.view.style.display = "block";

    self.renderTexture = new PIXI.RenderTexture(self.ctl.ctx, self.windowWidth, self.windowHeight);
    self.renderTexture2 = new PIXI.RenderTexture(self.ctl.ctx, self.windowWidth, self.windowHeight);

    self.currentTexture = self.renderTexture;

    self.outputSprite = new PIXI.Sprite(self.currentTexture);
    self.outputSprite.position.x = (self.windowWidth)/2;
    self.outputSprite.position.y = (self.windowHeight)/2;

    self.outputSprite.anchor.x = 0.5;
    self.outputSprite.anchor.y = 0.5;

    // self.stage.addChildAt(self.outputSprite, self.stage.children.length - 1);
    self.ctl.stampContainer.addChild(self.outputSprite);
  };



  self.stageTransitions = function(e) {

    // Add ===============
    if (e.fxAdd) {

      // rotate
      if (e.fxAdd.indexOf('rotate') > -1) {
        self.interStageRotation = self.interpolate(0, 1, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // rotate reverse
      if (e.fxAdd.indexOf('rotate-rev') > -1) {
        self.interStageRotation = self.interpolate(1, -1, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // rotate 3 (from -1 to 1)
      if (e.fxAdd.indexOf('rotate-3') > -1) {
        self.interStageRotation = self.interpolate(-1, 1, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // rotate double (siren)
      if (e.fxAdd.indexOf('rotate-d') > -1) {
        self.interStageRotation = self.interpolate(0, -1.569, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // rotate double reverse (-1.5696 to 1)
      if (e.fxAdd.indexOf('rotate-d-rev') > -1) {
        self.interStageRotation = self.interpolate(-1.569, 1, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // scale
      if (e.fxAdd.indexOf('scale') > -1) {
        self.interStageScale = self.interpolate(1, 0.75, 896, self.easeOut);
        self.interStageScaleI = 0;
      }
      // scale-half
      if (e.fxAdd.indexOf('scale-half') > -1) {
        self.interStageAlpha = self.interpolate(0.5, 0.3, 896, self.easeOut);
        self.interStageAlphaI = 0;
        self.interStageScale = self.interpolate(1, 0.93, 896, self.easeOut);
        self.interStageScaleI = 0;
      }
      // color matrix
      if (e.fxAdd.indexOf('color') > -1) {
        self.colorMatrix =  [1,0,0,0,
                    				 0,1,0,0,
                    				 0,0,1,0,
                    				 0,0,0,1];
        self.colorMatrixFilter = new PIXI.filters.ColorMatrixFilter();
        self.colorMatrixFilter.matrix = self.colorMatrix;
        self.ctl.stage.filters = [self.colorMatrixFilter];
      }
      self.working = true;
    }



    // Remove ============
    if (e.fxRemove) {

      // rotate
      if (e.fxRemove.indexOf('rotate') > -1) {
        self.interStageRotation = self.interpolate(1, 0, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // rotate reverse
      if (e.fxRemove.indexOf('rotate-rev') > -1) {
        self.interStageRotation = self.interpolate(-1, 0, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // rotate double (siren)
      if (e.fxRemove.indexOf('rotate-d') > -1) {
        self.interStageRotation = self.interpolate(-1.569, 0, 896, self.easeOut);
        self.interStageRotationI = 0;
      }
      // scale
      if (e.fxRemove.indexOf('scale') > -1) {
        self.interStageScale = self.interpolate(0.75, 1, 896, self.easeOut);
        self.interStageScaleI = 0;
      }
      // scale-half
      if (e.fxRemove.indexOf('scale-half') > -1) {
        self.interStageAlpha = self.interpolate(0.89, 1, 896, self.easeOut);
        self.interStageAlphaI = 0;
        self.interStageScale = self.interpolate(0.3, 1, 896, self.easeOut);
        self.interStageScaleI = 0;
      }
      self.working = true;
    }

    // alpha
    if (e.toggle === false) {
      self.interStageAlpha = self.interpolate(1, 0, 896, self.easeOut);
      self.interStageAlphaI = 0;
      self.working = true;
    }

    self.interStageI = 0;
  };


  self.clearFxElms = function() {
    if (self.outputSprite) {
      self.ctl.stampContainer.removeChild(self.outputSprite);
      self.outputSprite = self.renderTexture = self.renderTexture2 = null;
    }
  };

  self.hideFxElms = function() {
    if (self.outputSprite) {
      self.outputSprite.visible = false;
      self.visible = false;
      self.transitionOut = false;
      self.working = false;
    }
  };

  self.showFxElms = function() {
    if (self.outputSprite) {
      self.outputSprite.alpha = 1;
      self.outputSprite.visible = true;
      self.visible = true;
    }
  };


  self.resetFxElms = function() {
    self.visible = false;
    self.working = false;
    self.transitionOut = false;

    self.interStageI = 0;
    self.interStageScale = [];
    self.interStageScaleI = 0;
    self.interStageRotation = [];
    self.interStageRotationI = 0;
    self.interStageAlpha = [];
    self.interStageAlphaI = 0;

    self.clearFxElms();
    self.initFxElms();
    self.hideFxElms();

  };


  self.draw = function(offset) {
    if (self.visible) {

      if (!self.renderTexture) {
        self.initFxElms();
      }

      var temp = self.renderTexture;
  		self.renderTexture = self.renderTexture2;
  		self.renderTexture2 = temp;

  		// self.renderTexture.render(self.stage, true);
  		self.outputSprite.texture = self.renderTexture;

      // scale
      if (!(self.interStageScaleI >= 895)) {
        self.outputSprite.scale.x = self.outputSprite.scale.y = self.interStageScale[ self.interStageScaleI | 0 ];
      }

      // rotation
      if (!(self.interStageRotationI >= 895)) {
        self.outputSprite.rotation = self.interStageRotation[ self.interStageRotationI | 0 ];
      }

      // alpha
      if (!(self.interStageAlphaI >= 895)) {
        self.outputSprite.alpha = self.interStageAlpha[ self.interStageAlphaI | 0 ];
      } else {
        self.outputSprite.alpha = 1;
      }
      // color matrix
      // self.animateColorMatrix(offset);

      // Properties to try
      // shader
      // mask
      // skew

  		self.renderTexture2.render(self.stage, false);

      // tick interStage variables
      if (self.interStageScale[ (self.interStageScaleI + 1) | 0] !== undefined) { self.interStageScaleI += 1; }
      if (self.interStageRotation[ (self.interStageRotationI + 1) | 0 ] !== undefined) { self.interStageRotationI += 1; }
      if (self.interStageAlpha[ (self.interStageAlphaI + 1) | 0] !== undefined) { self.interStageAlphaI += 1; }
      self.interStageI+=1;

      // end transition
      if (self.interStageI >= 895 && self.working) {
        self.working = false;
        if (self.transitionOut) {
          self.hideFxElms();
        }
      }
    }

  };


  // Only works with WebGL Renderer
  self.animateColorMatrix = function(offset) {
    offset = offset/1000;
		self.colorMatrix[1] = Math.sin(offset) * 3;
		self.colorMatrix[2] = Math.cos(offset);
		self.colorMatrix[3] = Math.cos(offset) * 1.5;
		self.colorMatrix[4] = Math.sin(offset/3) * 2;
		self.colorMatrix[5] = Math.sin(offset/2);
		self.colorMatrix[6] = Math.sin(offset/4);
		self.colorMatrixFilter.matrix = self.colorMatrix;
  };


  self.interpolate = function (a, b, steps, ease) {
    if (a === b) return [a];
    var d = (b-a)/100;
    var c = [];
    for (var i = 0; i <= steps; i++) {
      c.push(a + d * ease(((100/steps)*i)/100) *100);
    }
    return c;
  };
  self.easeInOut = BezierEasing(0.42, 0.0, 0.58, 1.0);
  self.easeOut = BezierEasing(0.00, 0.0, 0.58, 1.0);


});


module.exports = FxController;
