var Collection = require('../postcard/Collection');


module.exports = {

  title: 'Ballad',
  index: 2,
  tempo: 155,
  meter: 4,
  collection: 'ballad',
  timestamps: [

    // Verse 1
    {
      song: 2,
      timestamp: '00.06.270',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale']
      }
    },



    // Chorus 1
    // {
    //   song: 2,
    //   timestamp: '00.21.754',
    //   actions: ['fx'],
    //   data: {
    //     fxAdd: ['rotate']
    //   }
    // },

    // Verse 2
    {
      song: 2,
      timestamp: '00.34.130',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate']
      }
    },

    // Bridge
    {
      song: 2,
      timestamp: '01.19.772',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate-rev']
      }
    },

    // Drop
    {
      song: 2,
      timestamp: '02.16.069',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate-rev']
      }
    },

    // Out
    {
      song: 2,
      timestamp: '02.44.876',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale']
      }
    }

    // // Pre bridge - tempo change
    // { timestamp: '01.14.322', actions: ['tempo'], tempo: 135 },
    //
    // // Bridge - tempo change
    // { timestamp: '01.19.655', actions: ['tempo'], tempo: 155 },
    //
    // // Vamp - tempo change
    // { timestamp: '01.58.817', actions: ['tempo'], tempo: 100 }
  ]

};
