function EventDispatcher() {
  var self = this;

  self.listeners = { };
  // useful for debugging
  self.spying = window.location.href.indexOf('spy=1') > -1;
  self.ignore = [];

  self.emit = function(action, data) {
    if(self.spying /* && !_.contains(self.ignore, action)*/) {
      console.log(
        '\n> Emitted event', action, 'to',
        (self.listeners[action] && self.listeners[action].length) || 0,
        'listener' + (!self.listeners[action] || self.listeners[action].length > 1 ? 's' : '')
      );
      console.log('> data:', data);
    }
    if(self.listeners[action]) {
      self.listeners[action].forEach( function(listener) {
        listener(data);
      });
    }
  };

  self.on = function(action, callback) {
    self.listeners[action] = (self.listeners[action] || []).concat(callback);
  };

  self.spy = function(toggle) {
    self.spying = toggle;
  };

  self.doNotSpyOn = function(list) {
    self.ignore = self.ignore.concat(list);
  }
}

window.EventDispatcherSingleton = new EventDispatcher();

module.exports = EventDispatcherSingleton;