var Collection = require('../postcard/Collection');

module.exports = {

  title: 'Three Stones',
  index: 1,
  tempo: 165,
  meter: 3,
  collection: 'three_stones',
  timestamps: [

    // Rough idea timestamps
    // { song: 1, timestamp: '00.01.000', actions: ['example'] },

    // intro
    // { song: 1,
    //   timestamp: '00.00.000',
    //   actions: ['']
    // },

    // vocal
    { song: 1,
      timestamp: '00.08.684',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale'],
      }
    },
    //
    // // before oo's
    // oo's
    { song: 1,
      timestamp: '00.25.850',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate']
      }
    },

    // oo's
    // { song: 1,
    //   timestamp: '00.26.350',
    //   actions: ['css-add'],
    //   data: {
    //     addBase: 'fx-a-hue--360-0',
    //     addTime: '24',
    //   }
    // },

    // verse 2
    { song: 1,
      timestamp: '00.48.308',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate']
      }
    },
    //
    // oo's
    // { song: 1,
    //   timestamp: '01.05.580',
    //   actions: ['css-add'],
    //   data: {
    //     addBase: 'fx-a-hue--360-0',
    //     addTime: '6',
    //   }
    // },
    // //
    // // // ahs's
    { song: 1,
      timestamp: '01.27.406',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale']
      }
    },
    // //
    // // // out
    // { song: 1,
    //   timestamp: '01.36.406',
    //   actions: ['fx'],
    //   data: {
    //     t
    //     fxRemove: ['rotate']
    //   }
    // }

  ]

};
