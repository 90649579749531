module.exports = {

  title: 'Sleeper',
  index: 4,
  tempo: 149,
  meter: 5,
  collection: 'sleeper',
  timestamps: [

    // first drop
    {
      song: 4,
      timestamp: '00.16.181',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale', 'rotate-d'],
      }
    },

    // vamp drop
    {
      song: 4,
      timestamp: '00.52.401',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate-d'],
      }
    },

    // "two by two"
    {
      song: 4,
      timestamp: '01.19.339',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale'],
      }
    },
    // "i'm all flustered"
    {
      song: 4,
      timestamp: '01.56.936',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale', 'rotate-d'],
      }
    },

    // "oo's"
    {
      song: 4,
      timestamp: '02.30.670',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale', 'rotate-d'],
      }
    },

    // Bridge - Meter change to 3
    { timestamp: '01:08:456', actions: ['meter'], meter: 3 }
  ]

};
