var Bacon = require('../../vendor/Bacon');
var Module = require('../eventDispatcher/Module');
var PostcardController = require('./PostcardController');

var player = require('../player');


var InputFeedback = new Module( function() {
  var self = this;

  self.ctl = PostcardController;
  self.charCount = 0;

  var $input  = $('#pc-input'),
      $linesTop = $('#pc-input-feedback').find('.top'),
      $linesLeftRight = $('#pc-input-feedback').find('.left, .right'),
      $lineBottomLeft = $('#pc-input-feedback').find('.bottom-left'),
      $lineBottomRight = $('#pc-input-feedback').find('.bottom-right'),
      $allLines = $('#pc-input-feedback').find('div');
      inputKeyUp = $input.asEventStream('keyup');

  // inputKeyUp.onValue(onKeyUp);

  function onKeyUp(e) {
    var n = $input.val().length;

    if (self.charCount >= 80) { return; }

    // reset
    if (n >= 40) {

      if ($input.val().lastIndexOf(' ') != -1) {

        $input.val().substring($input.val().lastIndexOf(' ') + 1);
        var split = $input.val().split(' ');
        var remainder = split.pop();
        self.ctl.addString(split.join(' '));
        $input.val(remainder);

      } else {

        self.ctl.addString($input.val());
        $input.val('');
      }
      self.charCount += n;

      // self.emit('stagefx', true);
      $allLines.css('background', 'rgba(40, 40, 40, 1)');
      return;
    }

    n = $input.val().length;
    var c = n + self.charCount;

    // scaling lines
    if (c < 20) {
      $allLines.css('background', 'rgba(40, 40, 40, 1)');

      $input.removeClass('color');
      // $input.css('color', '');
      $linesTop.css('transform', 'scaleX('+ c/20 +')');
      $linesLeftRight.css('transform', 'translateY(-100%)');
      $lineBottomLeft.css('transform', 'translateX(-100%)');
      $lineBottomRight.css('transform', 'translateX(100%)');

    } else if (c < 40) {
      $linesTop.css('transform', 'scaleX(1)');
      $linesLeftRight.css('transform', 'translateY(-'+ (100 - (((c - 20)/20)*100)) +'%)');
      $lineBottomLeft.css('transform', 'translateX(-100%)');
      $lineBottomRight.css('transform', 'translateX(100%)');

    } else if (c < 60) {
      $linesTop.css('transform', 'scaleX(1)');
      $linesLeftRight.css('transform', 'translateY(0%)');
      $lineBottomLeft.css('transform', 'translateX(-'+ (100 - (((c - 40)/20)*100)) +'%)');
      $lineBottomRight.css('transform', 'translateX('+ (100 - (((c - 40)/20)*100)) +'%)');
      $allLines.css('background', 'rgba(40, 40, 40, 1)');
      $input.removeClass('color');
    } else if (c >= 60) {
      $linesLeftRight.css('transform', 'translateY(0%)');
      $lineBottomLeft.css('transform', 'translateY(0%)');
      $lineBottomRight.css('transform', 'translateY(0%)');

      $input.addClass('color');
      $allLines.css('background', '#faff1d');
    }

    // colors
    // if (c < 4) {
    //   $allLines.css('background', 'rgba(98, 98, 98, 0.9)');
    // } else if (c < 7) {
    //   $allLines.css('background', 'rgba(40, 40, 40, 0.9)');
    // } else if (c < 9) {
    //   $allLines.css('background', 'rgba(40, 40, 40, 0.9)');
    // } else if (c < 14) {
    //
    // }

    // reset
    if (e.keyCode == 13) {
      self.charCount = c;

      if (c >= 60) {
        // self.emit('stagefx', true);
        $allLines.css('background', 'rgba(40, 40, 40, 1)');
      }

    }
  }

  self.resetFb = function() {
    $lineBottomLeft.css('transform', 'translateX(-100%)');
    $lineBottomRight.css('transform', 'translateX(100%)');

    if ($input.val().length + self.charCount > 20) {
      setTimeout(function(){
        $linesTop.css('transform', 'scale(0)');
        $allLines.css('background', 'rgba(98, 98, 98, 0.9)');
      }, 400);
      setTimeout(function(){
        $linesLeftRight.css('transform', 'translateY(-100%)');
      }, 200);
    } else {
      $allLines.css('background', 'rgba(98, 98, 98, 0.9)');
      $linesTop.css('transform', 'scale(0)');
      $linesLeftRight.css('transform', 'translateY(-100%)');
    }
  };

  self.on('canvas:clear', function(clear) {
    self.resetFb();
    self.charCount = 0;
    self.emit('stagefx', false);
  });


});
