var Bacon = require('../../vendor/Bacon');


var PostcardView = function(ctl, stage, ratio) {
  var self = this;

  self.ctl = ctl;
  self.width = ctl.windowWidth;
  self.height = ctl.windowHeight;

};



PostcardView.prototype.draw = function(stamps) {
  var self = this;

  for (var i = 0; i < stamps.length; i++) {
      self.drawOne(stamps[i]);
  };

};

PostcardView.prototype.drawOne = function(stamp) {
  var self = this;

  // update sprite position
  stamp.sprite.position.x = stamp.x;
  stamp.sprite.position.y = stamp.y + stamp.yCurrent;

  // update spite scale
  stamp.sprite.scale.x = stamp.scale;
  stamp.sprite.scale.y = stamp.scale;

};


module.exports = PostcardView;
