var Module = require('../eventDispatcher/Module');
var frames = require('../eventDispatcher/frames');
var context = require('./context');
var createAudioMeter = require('./meter');
var songs = require('./songs');


var webaudio = new Module( function() {
	var self = this;

	// buffers are kept in here
	var tracks = new Array(songs.length);

	// state
	var currentTrack = 2;
	var playing = false;
	var sample;
	var songStart = 0;
	var songDuration = 0;
	var pausedAt  = 0;

	// we'll use these audio nodes to
	// put together the audio line.
	// first there's the gain...
	var gain = context.createGain();
	var meter = createAudioMeter(context);
	gain.gain.value = 1;
	gain.connect(context.destination);

	// ========================================
	// FIRST THERE WAS ONE
	// ========================================

	getTrack(songs[2], function(buffer) {
		tracks[2] = buffer;
		self.emit('player:ready');
		setTrack(2);
		self.emit('player:track', songs[currentTrack]);
	});

	// ========================================
	// INCOMING EVENTS
	// ========================================

	self.on('player:toggle', function() {
		if (playing) {
			pause();
		} else {
			play();
		}
	});

	self.on('player:goToTrack', function(i) {
		currentTrack = i;
		setTrackAndPlay(currentTrack);
	});

	self.on('player:next', next);

	self.on('player:previous', previous);


	// ========================================
	// NASTY GUTS
	// ========================================

	function play() {
		if(!playing) {
			// how far were we through the song?
			var progress = pausedAt - songStart;
			// if the song picked up now, what would its
			// virtual start point have been? This is
			// useful for our progress stream.
			songStart = context.currentTime - progress;
			// make some sound
			sample = context.createBufferSource();
			sample.buffer = tracks[currentTrack];
			sample.connect(meter);
			sample.connect(gain);
			// start the sample now at our progress point
			sample.start(context.currentTime, progress);
			sample.onended = function() {
				// this will only happen if we're a the end
				// of the song.
				if (playing) {
					next();
				}
			};

			playing = true;
			self.emit('player:state', playing);
		}
	}

	function pause() {
		if(playing) {
			// put it slightly in the future for the sake of accuracy
			pausedAt = context.currentTime + 0.01;
			sample.onended = null;
			sample.stop(pausedAt);
			playing = false;
			self.emit('player:state', playing);
		}
	}

	function next() {
		if (currentTrack < tracks.length - 1) {
			currentTrack++;
			setTrackAndPlay(currentTrack);
		} else if (currentTrack === tracks.length - 1) {
			finish();
		}
	}


	function previous() {
		if (currentTrack > 0) {
			currentTrack--;
			setTrackAndPlay(currentTrack);
		}
	}

	// setTrack resets the state and readies
	// the track to be played from the beginning.
	function setTrack(currentTrack) {
		pause();
		songStart = 0;
		songDuration = tracks[currentTrack].duration;
		pausedAt = 0;
	}

	// setTrackAndPlay takes into account
	// the fact that we might have to go
	// fetch the song before we can play it.
	function setTrackAndPlay(trackNumber) {
		self.emit('player:track', songs[trackNumber]);
		if (tracks[trackNumber]) {
			setTrack(trackNumber);
			play();
		} else {
			self.emit('player:loading', true);
			pause();
			getTrack(songs[trackNumber], function(buffer) {
				// check if this is still the track
				// we want to play
				tracks[trackNumber] = buffer;
				if (currentTrack === trackNumber) {
					self.emit('player:loading', false);
					setTrack(currentTrack);
					play();
				}
			});
		}
	}

	// this is called when the last song has finished playing
	function finish() {
		self.emit('player:state', false);
		self.emit('player:finished');
	}

	// progress returns the array:
	// 		[currentTrack, position as 0-1, position as timestamp]
	self.progress = frames.map(function() {
	  return playing ?
	    [currentTrack, (context.currentTime - songStart) / songDuration, (context.currentTime - songStart)] :
	    [currentTrack, (pausedAt - songStart) / songDuration, (pausedAt - songStart)]
	  ;
	});

	self.volume = frames.map(function() {
		return meter.volume;
	});
});

function getTrack(track, callback) {
  var request = new XMLHttpRequest();
  request.open("GET", '/audio/' + track.file, true);
  request.responseType = "arraybuffer";

  request.onload = function() {
    context.decodeAudioData(request.response, callback);
  };

  request.send();
}

module.exports = webaudio;
