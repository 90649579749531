var Module = require('../eventDispatcher/Module');
var Bacon = require('../../vendor/Bacon');
var PostcardView = require('./PostcardView');
var Stamp = require('./Stamp');
var TextStamp = require('./TextStamp');
var Sequencer = require('./Sequencer');
var FxController = require('./FxController');
var settings = require('./settings');
var utils = require('./utils');
var PIXI = window.PIXI;


var PostcardController = new Module( function() {
  var self = this;

  self.ctx;
  self.canvas;
  self.view;

  self.strings = [];
  self.elms = [];
  self.previewElms = [];
  self.playing = false;
  self.offsetD = 0;

  // DOM
  self.$input = $('#pc-input');


  // Init

  self.on('postcard-app:init', function() {
    // - init the canvas and draw the initial timeline
    self.initView();
    // start the sequence controller
    // and pass it the view for modifying
    self.initSequence();
  });


  // Privite API ===========================================================

  self.initView = function () {
    // CHROME behaves better with canvas
    var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    if (isChrome) {
      self.ctx = new PIXI.CanvasRenderer(window.innerWidth, window.innerHeight);
    } else {
      // Everything else webGL
      self.ctx = new PIXI.autoDetectRenderer(window.innerWidth, window.innerHeight);
    }


    // canvas for now, gives us more blend modes
    // self.ctx = new PIXI.CanvasRenderer(window.innerWidth, window.innerHeight);

    // add to DOM
    var $container = $('.pc-text-input');
    $container.append(self.ctx.view);

    // grab it
    self.$canvas = $('canvas');
    self.$canvas.prop('id', 'pc-canvas');

    // set window variables
    self.ratio = utils.PIXEL_RATIO;
    self.ctx.ratio  = self.ratio;
    self.windowHeight = window.innerHeight;
    self.windowWidth = window.innerWidth;
    self.ctx.backgroundColor = 0xede9d8;
    // self.ctx.backgroundColor = 0x949081;
    // self.ctx.backgroundColor = 0x333333;


    // Poscart view
    self.view = new PostcardView(self, self.stage, self.ratio);

    // Elm Containers
    self.bgContainer = new PIXI.Container();
    self.stampContainer = new PIXI.Container();
    self.bgContainer.zIndex = 0;
    self.stampContainer.zIndex = 10;

    // set new stage
    self.stage = new PIXI.Stage(0xffffff);
    self.stage.addChild(self.bgContainer);
    self.stage.addChild(self.stampContainer);


    // Fx Controller
    self.fx = FxController;
    self.fx.stage = self.stage;
    self.fx.ctl = self;
  };

  self.initSequence = function() {
    self.sequencer = Sequencer;
    self.sequencer.windowHeight = self.ctx.height;
    self.sequencer.ctl = self;
  };



  // Drawing API ============================================================


  self.draw = function() {
    if (self.immediate) {
      return self.immediate();
    }
    // get offset now so we can provide the same
    var offset = self.sequencer.offset();

    // duplcates for seamless
    var duplicates = self.sequencer.orderDuplicates(offset);
    self.view.draw(duplicates);

    // sequence elements
    var ordered = self.sequencer.orderElements(self.elms, offset);
    self.view.draw(ordered);

    // sequence preview elements
    var preview = self.sequencer.orderElements(self.previewElms, offset);
    self.view.draw(preview);

    // FX
		self.fx.draw(offset);

    // render stage
    self.ctx.render(self.stage);

    // tick
    self.sequencer.tickFrame();

    // update progress
    // self.sequencer.updateProgress((self.sequencer.length - (self.sequencer.length - offset))/self.sequencer.length);
  };


  self.setCollection = function(collection) {
    var self = this;
    self.collection = collection;
  };


  self.emptyNow = true;
  self.emptyRefresh = function() {

    // did we just become empty?
    if (!self.emptyNow && self.isEmpty()) {
      self.emptyNow = self.isEmpty();
      self.fx.hideFxElms();

    // did we just become not empty?
    } else if (self.emptyNow && !self.isEmpty()) {
      self.emptyNow = self.isEmpty();
      self.fx.showFxElms();
    }
  };


  self.isEmpty = function() {
    if (!self.elms.length && !self.previewElms.length) {
      return true;
    } else {
      return false;
    }
  };


  // Add string of words to elms array
  self.addString = function(string) {
    if (!self.collection) {
      console.error('Error in PostcardView.refreshElms: No collection set');
      return;
    }

    var newElms = self.previewElms;
    self.previewElms = [];

    // add to elms
    self.elms = self.elms.concat(newElms);

    // recalc duplicate elements for seamless
    var duplicates = self.sequencer.calcDuplicates(newElms, self.ctx);
    self.sequencer.addDuplicates(duplicates);

    // Add Text String
    self.addTextStringToCanvas(string);

    // Limit max elms
    self.limitElms();
  };


  // Print text in Postcard
  self.addTextStringToCanvas = function(string) {
    var color = self.$input.css('color');
    var newElm = new TextStamp(string, self.ctx, self.sequencer.yD(), {}, color);
    self.elms.push(newElm);
    self.stampContainer.addChild(newElm.sprite);
    var duplicates = self.sequencer.calcDuplicates([newElm], self.ctx);
    self.sequencer.addDuplicates(duplicates);
  };


  // Live preview string (before enter is clicked)
  self.updatePreviewString = function(string) {
    if (!self.collection) {
      return console.error('Error in PostcardView.refreshElms: No collection set');
    }

    var strings = utils.sanitizeStrings(string.split(' '));

    if (self.elms.length < 100) {
      strings = self.transformStrings(strings);
    }

    var newElms = self.makeNewElms(strings);

    // remove old from stage
    self.removeElmsFromStage(self.previewElms);

    // set new ones
    self.previewElms = newElms;

    // add new to stage
    self.addElmsToStage(self.previewElms);

    // add preview duplcates
    self.sequencer.setPreviewDuplicates(self.previewElms, self.ctx);

    // refresh empty state
    self.emptyRefresh();
  };


  self.makeNewElms = function(strings) {
    // check if we have a collection
    if (!self.collection.molds) {
      return [];
    }
    return strings.map(function(string, i) {
      // check if we have the character in this collection
      if (self.collection.molds[string.charAt(0).toLowerCase()] === undefined) {
        // console.log('letter "', string.charAt(0).toLowerCase(),'" is undefined in Collection ', self.collection.id);
        return self.collection.molds['a'].newStamp(self.ctx, string, self.sequencer.yD());
      }

      // is this the same string as before?
      if (self.previewElms[i] && self.previewElms[i].str === string) {
        // inherit position
        return self.previewElms[i].mold.newStamp(self.ctx, string, self.previewElms[i].yD, self.previewElms[i].props, { x: self.previewElms[i].x,  y: self.previewElms[i].y,  z: self.previewElms[i].z });
      }

      // make the first element a background
      // if (self.elms.length === 0 && i === 0) {
      //   // get the fisrt bg mold in the collection
      //   return self.firstBackground(string);
      // }

      // make a mold from the first character, pass it the entire string
      return self.collection.molds[string.charAt(0).toLowerCase()].newStamp(self.ctx, string, self.sequencer.yD());
    });
  };


  self.firstBackground = function(string){
    var bgs = [];
    // first check if it happens to be a bg character
    if (self.collection.molds[string.charAt(0).toLowerCase()].props.bg === true) {
      return self.collection.molds[string.charAt(0).toLowerCase()].newStamp(self.ctx, string, self.sequencer.yD());
    }
    // if not, find all the backgrounds in the collection and pick a random one
    for (var i = 0; i < Object.keys(self.collection.molds).length; i++) {
      var temp = self.collection.molds[Object.keys(self.collection.molds)[i]];
      if (temp.props.bg === true) {
        bgs.push(temp);
      }
    }
    var mold = bgs[Math.floor(Math.random()*bgs.length)];
    return mold.newStamp(self.ctx, string,self.sequencer.yD());
  };


  // STAGE API ===================================================

  // Clear Canvas
  self.clearCanvas = function() {
    // set next draw
    self.setImmediate(function() {
      // clear stage
      while(self.stampContainer.children[0]) {
        self.stampContainer.removeChild(self.stampContainer.children[0]);
      }
      while(self.bgContainer.children[0]) {
        self.bgContainer.removeChild(self.bgContainer.children[0]);
      }

      // nullify and delete all elms =================================
      for (var i = 0; i < self.elms.length; i++) {
        self.elms[i] = null;
      }
      self.elms = [];
      for (var i = 0; i < self.strings.length; i++) {
        self.strings[i] = null;
      }
      self.strings = [];
      for (var i = 0; i < self.previewElms.length; i++) {
        self.previewElms[i] = null;
      }
      self.previewElms = [];

      // reset input
      self.$input.val('');

      // reset fx elms
      self.fx.resetFxElms();
    });

    self.emit('canvas:clear', true);
  };

  self.addElmsToStage = function(elms) {
    for (var i = 0; i < elms.length; i++) {
      if (elms[i].props.bg) {
        // add to bg container
        self.bgContainer.addChild(elms[i].sprite);
      } else  {
        // add to stamp container
        self.stampContainer.addChild(elms[i].sprite);
      }
    }
  };

  self.removeElmsFromStage = function(elms) {
    for (var i = 0; i < elms.length; i++) {
      if (elms[i].props.bg) {
        // remove from bg container
        self.bgContainer.removeChild(elms[i].sprite);
      } else  {
        // remove from stamp container
        self.stampContainer.removeChild(elms[i].sprite);
      }
      elms[i] = null;
    }
  };


  // set Immediate allows for interupting the requestAnimationFrame
  self.setImmediate = function(fn) {
    self.immediate = function() {
      fn();
      self.immediate = null;
    }
  };



  self.stageNewCanvas = function(nextSong) {
    // Set new Collection
    self.setCollection(nextSong.collection);
  };




  // SAVE API ================================================================

  self.saveElms = function() {
    var savedElms = [];
    for (var i = 0; i < self.elms.length; i++) {
      savedElms.push(self.elms[i].export());
    }
    return savedElms;
  };

  self.importElms = function(data) {
    var elms = data.elms;
    var newElms = [];
    var modDiff = data.startMod - self.sequencer.startMod;

    for (var i = 0; i < elms.length; i++) {
        var newStamp;

        if (elms[i].type === 'stamp') {
          // recalc texture
          var mold = elms[i].mold;
          mold.texture = PIXI.Texture.fromImage(elms[i].srcImage);
          newElms.push( new Stamp(mold, self.ctx, elms[i].str, elms[i].yD, elms[i].props, elms[i].pos));
        } else if (elms[i].type === 'text-stamp') {
          newElms.push( new TextStamp(elms[i].str, self.ctx, elms[i].yD, {}, elms[i].color, elms[i].pos));
        }

    }
    self.elms = self.elms.concat(newElms);

    // add to stage
    self.addElmsToStage(newElms);

    // recalc duplicate elements for seamless
    var duplicates = self.sequencer.calcDuplicates(newElms, self.ctx);
    self.sequencer.addDuplicates(duplicates);

    // pretend that we started
    self.emit('firstKey');
  };



  // SONGS API  ===============================================================

  self.on('player:state', function(playing) {
    if (!playing) {
      self.offsetD = self.sequencer.offset();
    }
    self.playing = playing;
  });



  // HELPERS ==================================================================


  self.transformStrings = function(strings) {
    var transformed = [];

    // take the substring for every letter
    for (var i = 0; i < strings.length; i++) {
      // for ever character
      var s = strings[i];
      for (var j = 0; j < s.length; j++) {
        var temp = s;
        if (''.repeat !== undefined) {
          transformed.push(temp.substring(j).substring(0, 1) + ' '.repeat(j)); // add white space for blend modes
        } else {
          transformed.push(temp.substring(j).substring(0, 1));
        }
      }
      // only do the first 3 strings
      if (i >= 3 || j > 10) {
        transformed = transformed.concat(strings.splice(i));
        break;
      }
    }
    return transformed;
  };


  self.limitElms = function() {
    var max = 50;
    if (self.elms.length > max) {
        var d = self.elms.length - max;
        for (var i = 0; i < d; i++) {
          if (self.elms[i].props.bg) {
            self.bgContainer.removeChild(self.elms[i].sprite);
          } else {
            self.stampContainer.removeChild(self.elms[i].sprite);
          }
          self.elms[i] = null;
        }
        self.elms.splice(0, d);
        self.sequencer.resetDuplcates(self.sequencer.calcDuplicates(self.elms, self.ctx));
    }
  };

});



module.exports = PostcardController;
