var Module = require('../eventDispatcher/Module');

var player = new Module( function() {
	var self = this;

	// ========================================
	// CONTROLS
	// ========================================

	var $play = $('[data-player-play]');
	var $previous = $('[data-player-prev]');
	var $next = $('[data-player-next]');
	var $progress = $('[data-player-progress]');
	var $title = $('[data-player-title]');
	var $trackItem = $('[data-track-item]');

	// ========================================
	// INCOMING EVENTS
	// ========================================

	// this is a one-time event that indicates the
	// player is ready to party after startup.
	self.on('player:ready', function() {
		$play.removeClass('loading');
	});

	// the player:track event gives us track info.
	self.on('player:track', function(trackData) {
		$title.text(trackData.title);
		$trackItem.removeClass('active');
		$trackItem.eq(parseInt(trackData.number - 1)).addClass('active');
	});

	// we don't keep the state of the player in this
	// module; instead we react to the player:state
	// event, which will tell us if we're playing.
	self.on('player:state', function(playing) {
		$play.toggleClass('paused', !playing);
	});

	// the loading state is set via this event.
	self.on('player:loading', function(loading) {
		$play.toggleClass('loading', loading);
	});

	// ========================================
	// OUTGOING EVENTS
	// ========================================

	$play.on('click', function(e) {
		if ($play.hasClass('loading')) return;
		self.emit('player:toggle');
	});

	$previous.on('click', function(e) {
		self.emit('player:previous');
	});

	$next.on('click', function(e) {
		self.emit('player:next');
	});

	$trackItem.on('click', function(e) {
		var i = parseInt($(this).attr('data-index'));
		$trackItem.removeClass('active');
		$(this).addClass('active');
		self.emit('player:goToTrack', i);
	});



	self.progress = function(amt) {
		// the right property needs to go from 100% to 0%
		$progress.css('right', 100 - (amt * 100) + '%');
	}
});

module.exports = player;
