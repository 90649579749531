var Module = require('../eventDispatcher/Module');
var Bacon = require('../../vendor/Bacon');
var PostcardController = require('./PostcardController');
var Sequencer = require('./Sequencer');


var PostcardSession = new Module( function() {
  var self = this;

  self.ctl = PostcardController;
  self.sequencer = Sequencer;

  self.loaded = true;


  // Save button click
  self.on('postcard:save', function() {
    var savedElms = self.ctl.saveElms();
    var startMod = self.sequencer.startMod;

    $.ajax({
      url: '/save',
      type: 'POST',
      data: JSON.stringify({
        elms: savedElms,
        startMod: startMod,
       }),
      contentType: 'application/json',
      success: function(response) {
        self.emit('postcard:saveResult', response);
      },
      error: function(e) {
        console.log('e', e);
      }
    });
  });


  // Detect Saved Session
  if (window.location.pathname.length > 0) {
    var sessionId = window.location.pathname.split('/').pop();
    if (sessionId && sessionId.length) {
      var loaded = false;

      self.emit('session:loading');

      $.ajax({
        url: '/session/' + sessionId,
        type: 'GET',
        success: function(response) {
          if (self.loaded) {
            // import now
            self.ctl.importElms(JSON.parse(response));
            self.emit('session:loaded', response);

          } else {
            // wait till loaded
            self.sessionData = JSON.parse(response);
          }
        }
      });

    }
  }

  self.on('collection:loaded', function(loaded) {
    self.loaded = loaded;
    if (loaded && self.sessionData) {
      self.ctl.importElms(self.sessionData);
      self.sessionData = null;
    }
  });

});
