var Bacon = require('../../vendor/Bacon');
var webaudio = require('./webaudio');
var songs = [];

// Pearly Whites
songs.push(require('../songs/0_pearlyWhites').timestamps);

// Three Stones
songs.push(require('../songs/1_threeStones').timestamps);

// Ballad
songs.push(require('../songs/2_ballad').timestamps);

// Ranges
songs.push(require('../songs/3_ranges').timestamps);

// Sleeper
songs.push(require('../songs/4_sleeper').timestamps);

 // Siren
songs.push(require('../songs/5_siren').timestamps);

 // Siren End
songs.push(require('../songs/6_sirenEnd').timestamps);

 // It Won't Change Now
songs.push(require('../songs/7_wontChangeNow').timestamps);

 // Junkyard
songs.push(require('../songs/8_junkyard').timestamps);

 // Vessels
songs.push([]);


// =============================================
// Turn the timestamps into seconds
// =============================================

var song_markers = songs.map(function(timestamps) {
	return timestamps.map(function(t) {
		var parts = t.timestamp.split('.');
		var time = +parts[0] * 60;
		time += +parts[1];
		time += (+parts[2] * 1/1000);
		t.time = time;
		return t;
	});
});

// given an array and a time value, this returns the
// actions closest to the time. We'll deduplicate the
// stream itself so that it becomes a more useable event.
function find_action(arr, val) {
	for(var i = arr.length - 1; i >= 0; i--) {
		if(val >= arr[i].time && val < (arr[i].time + 1)) {
			return arr[i];
		}
	}
	return null;
}

var structure = new Bacon.Bus();
// progress comes in the form [track #, position %, position timestamp]
webaudio.progress.onValue( function(data) {
	if (data[1] === 0) return;
	structure.push(find_action(song_markers[data[0]], data[2]));
});

var changes = structure.skipDuplicates();

module.exports = changes;
