var Module = require('../eventDispatcher/Module');
var Mold = require('./Mold');

// Collection of images, for each song.
// takes a callback for the image loading
function Collection(id, callback) {
  var self = this;

  self.id = id;
  self.molds = {};
  self.m = new Module( function() {});

  self.generateMolds(callback);
}

Collection.prototype.generateMolds = function(callback) {
  var self = this;

  var a = 65; // 'a' char code offset
  var sources = [];

  // <img> for this collection have #(self.id _ i)
  for (var i = 0; i < 27; i++) {

    // default to first elm if there's not engouh images
    var img = document.getElementById(self.id + '_' +i) || document.getElementById(self.id + '_' +0);
    if (img) {
      sources.push(img.getAttribute('src'));
      self.molds[String.fromCharCode(i+a).toLowerCase()] = new Mold(img.getAttribute('src'), self.gatherProperties(img));
      img = null;
    }
  }
  if (sources[0] === undefined) {
    return callback(null);
  }
  self.detectLoaded(sources, callback);
}

Collection.prototype.gatherProperties = function(img) {
  var props = {};

  // background
  if (img.hasAttribute('pc-bg')) {
    props.bg = true;
  }

  // blend
  if (img.hasAttribute('pc-blend-normal')) {
    props.blend = 'normal';
  }
  return props;
}

Collection.prototype.detectLoaded = function(sources, callback) {
  var self = this;
  var i = 0;

  self.m.emit('collection:loaded', false);

  var loadImage = function() {
      var img = new Image();
      img.onload = function () {
          ++i;
          img = null;

          if(i < sources.length) {
            return loadImage();
          } else {
            self.m.emit('collection:loaded', self.id);
            return callback(null);
          }
      }
      img.src = sources[i];
  }
  loadImage();
};

module.exports = Collection;
