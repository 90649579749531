// Not all devices have the same pixil ratio.
// We need to inform canvas what ratio we're
// working with, and configure it with relative
// width and height values.

var utils = {};

utils.PIXEL_RATIO = (function () {
    var ctx = document.createElement("canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;

    return dpr / bsr;
})();


utils.createHiDPICanvas = function(canvas, w, h, ratio) {
    if (!ratio) { ratio = utils.PIXEL_RATIO; }
    canvas.width = w * ratio;
    canvas.height = h * ratio;
    canvas.style.width = w + "px";
    canvas.style.height = h + "px";
    canvas.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
    return canvas;
};

utils.debounce = function (func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};


utils.sanitizeStrings = function(strings) {
  var self = this;

  // filter out spaces
  var filtered = strings.filter(function(string) {
    if (!string.charAt(0)) {
      return false;
    }
    if (string === ' ') {
      return false
    }
    return true;
  });

  return filtered;
};

utils.getSongs = function() {
  return [
    require('../songs/0_pearlyWhites'),
    require('../songs/1_threeStones'),
    require('../songs/2_ballad'),
    require('../songs/3_ranges'),
    require('../songs/4_sleeper'),
    require('../songs/5_siren'),
    require('../songs/6_sirenEnd'),
    require('../songs/7_wontChangeNow'),
    require('../songs/8_junkyard')

  ];
};

window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
          };
})();

module.exports = utils;
