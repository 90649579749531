module.exports = {

  title: 'Siren',
  index: 5,
  tempo: 159,
  meter: 3,
  collection: 'siren',
  timestamps: [

    // first drop
    {
      song: 5,
      timestamp: '00.45.099',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale'],
      }
    },

    // verse1b, "still i see"
    {
      song: 5,
      timestamp: '01.07.757',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate']
      }
    },

    // vamp
    {
      song: 5,
      timestamp: '01.39.453',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate-rev']
      }
    },

    // bridge, "everything"
    {
      song: 5,
      timestamp: '02.36.031',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate-3']
      }
    },

    // "so much time in the world today"
    {
      song: 5,
      timestamp: '03.21.514',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate']
      }
    },

    // drop
    {
      song: 5,
      timestamp: '03.57.572',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate-d']
      }
    },

    // outro start
    {
      song: 5,
      timestamp: '04.33.804',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate-d']
      }
    },

    // out
    {
      song: 5,
      timestamp: '05.07.897',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale']
      }
    },

  ]

};
