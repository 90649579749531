module.exports = {

  title: "It Won't Change Now",
  index: 7,
  tempo: 275,
  meter: 4,
  collection: 'iwcn',
  timestamps: [
    // "it wont change *now*"
    // {
    //   song: 7,
    //   timestamp: '00.11.454',
    //   actions: ['fx'],
    //   data: {
    //     toggle: true,
    //     fxAdd: ['scale'],
    //   }
    // },

    // Chorus
    {
      song: 7,
      timestamp: '00.55.519',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale'],
      }
    },

    // Verse 2
    {
      song: 7,
      timestamp: '01.24.323',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate-d'],
      }
    },

    // Chorus 2 out
    {
      song: 7,
      timestamp: '02.24.057',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale', 'rotate-d'],
      }
    }
  ]

};
