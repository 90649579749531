var Bacon = require('../../vendor/Bacon');
var Module = require('../eventDispatcher/Module');
// var PostcardController = require('./PostcardController');
// var player = require('../player');


var Intro = new Module( function() {
  var self = this;

  self.$introLogo = $('.pc-intro-logo');
  self.$input = $('#pc-input');


  self.on('firstKey', function() {
    self.$introLogo.addClass('hide');
    self.$input.addClass('active');

    // destroy it
    setTimeout(function() {
      self.$introLogo.remove();
    }, 4000);

    // set tooltop for "enter" key press
    // setTimeout(function(){
    //   self.emit('tooltip', "Press 'Enter' to commit text.");
    // }, 2500);
  });

});
