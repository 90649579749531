var EventDispatcher = require('./EventDispatcher.js');

// this is our parent class.
// it has event emitting built in.
// it accepts the entire concrete
// module implementation as a function
// and extends it by binding it to the
// parent class's scope.

function Module(concreteModule) {
  var self = this;

  self.on   = EventDispatcher.on;
  self.emit = EventDispatcher.emit;

  // call the concrete module here so that it
  // gains access to the functions above.
  concreteModule.apply(this, []);
}

/*

USAGE
-----

var myModule = new Module( function() {
  var self = this;
  // do what you would normally do here
});

*/

module.exports = Module;