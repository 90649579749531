var blendModes = require('./settings').blendModes;
var _ = require('lodash');
var utils = require('./utils');
var PIXI = window.PIXI;


// STAMP is an item on the canvas, created from a Mold

// Supported Properties
// - bg

function Stamp(mold, ctx, str, yD, props, pos) {
  var self = this;

  self.type = 'stamp';
  self.mold = mold;
  self.str = str;
  self.yD = yD;                                      // original position for sequencer
  self.yCurrent = 0;                                 // refreshed by sequencer
  self.sprite = new PIXI.Sprite(mold.texture);       // Sprite object rendered by PIXI, if its a Text type there is no Mold

  // properties
  self.props = props || {};

  // if not pos inherited
  self.calcPosition(ctx, pos);
  self.calcScale(ctx);

  // set blend mode
  self.calcBlendMode();
}



Stamp.prototype.calcPosition = function(ctx, pos){
  var self = this;

  // inherited?
  if (pos !== undefined) {
    self.x = pos.x;
    self.y = pos.y;
    self.z = pos.z;
    self.pos = pos;
    self.yCurrent = self.yD - self.yD*self.z;
    return;
  }

  // background
  if (self.props.bg) {
    self.x = 0;
    self.y = 0;
    self.z = 0.8 + 0.5*Math.random();
    self.yCurrent = self.yD - self.yD*self.z;
    return;
  }



  // Normal
  self.z = 0.5 + 1.5*Math.random();
  self.x = (Math.max(Math.random()*ctx.width - 200, -50)) | 0; // TODO get half of width here
  self.y = (100 + self.z*((ctx.height)/2) + self.z*Math.random()*((ctx.height)/6)) | 0;
  self.yCurrent = self.yD - self.yD*self.z;

}

Stamp.prototype.calcScale = function(ctx) {
  var self = this;

  // Bacgkround
  if (self.props.bg) {
    self.scale = (ctx.width/self.sprite.width);
  } else {

    self.scale = 1;

  }
}

Stamp.prototype.calcBlendMode = function(i) {
  var self = this;

  if (self.props.blend && !self.props.bg) {
    self.sprite.blendMode = PIXI.BLEND_MODES[self.props.blend.toUpperCase()];
    return;
  }

  if (i === undefined) { i = 0 }

  // Background
  if (self.props.bg) {
    // self.sprite.blendMode = PIXI.BLEND_MODES.OVERLAY;
    self.sprite.blendMode = PIXI.BLEND_MODES.NORMAL;
  } else {

    // String Length
    var mode = blendModes[Math.min(self.str.length + i, blendModes.length - 1)].toUpperCase();
    self.sprite.blendMode = PIXI.BLEND_MODES[mode] || PIXI.BLEND_MODES.NORMAL;
  }
}

Stamp.prototype.export = function() {
  var self = this;

  return {
    type: 'stamp',
    srcImage: self.mold.src,
    mold: self.mold,
    str: self.str,
    yD: self.yD,
    yCurrent: 0,
    scale: self.scale,
    props: self.props,
    pos: {
      x: self.x,
      y: self.y,
      z: self.z
    }
  };
};


module.exports = Stamp;
