var Module = require('../eventDispatcher/Module');
var utils = require('./utils');
var Stamp = require('./Stamp');
var TextStamp = require('./TextStamp');
var _ = require('lodash');


var Sequencer = new Module( function() {
  var self = this;

  self.length = window.outerHeight > 0 ? 2*window.outerHeight : 2*window.innerHeight;  // Safari uses innerHeight
  self.timeScale = 0.1;  // scale Data object ms values
  self.speed = 0.12;
  self.windowHeight = window.outerHeight;
  self.duplicates = [];
  self.previewDuplicates = [];
  self.tick = 0;
  self.lastTickTime = 0;
  self.ctl;  // Set by PostcardController


  // calculate if elements are in frame,
  // and apply their offset
  self.orderElements = function(elms, offset) {

    var ordered = [];

    // loop through the elements
    for (var i = 0; i < elms.length; i++) {
      // is elm in view?

      // TODO need elm height
      // process y offset and apply to elm
      // - add z factor
      elms[i].yCurrent = elms[i].yD - offset*elms[i].z;

      // add to ordered
      ordered.push(elms[i]);

    }
    return ordered;
  };


  // Sequence duplicate elements
  self.orderDuplicates = function(offset) {
    // we need both preview and canvas duplicates
    var allElms = self.duplicates.concat(self.previewDuplicates);

    for (var i = 0; i < allElms.length; i++) {

      // We duplicate each element twice.
      // Once in the front, once in the back.
      // They alternate in the array, so we can use the index
      // to decide which one we are sequencing.
      var dOffset = (i % 2 === 0) ? -self.length*allElms[i].z : self.length*allElms[i].z;
      allElms[i].yCurrent = allElms[i].yD + dOffset - offset*allElms[i].z;
    }
    return allElms;
  };


  // Duplicate all elements that appear in the first
  // or last frame.  We will use them to create a buffer
  // for a seamless effect.
  self.calcDuplicates = function(elms, ctx) {
    var self = this;

    var duplicates = [];

    for (var i = 0; i < elms.length; i++) {
      // var yStart = elms[i].yD + elms[i].y;
      // var yEnd = yStart + elms[i].sprite.height*elms[i].scale;

      // Every element is doubled twice.  Once for a front duplicate
      // and again for a back duplicate

      // TODO: we can improve this by only forcing front + back duplicates for
      // backgrounds and not also stamps.  Since stamps are unlikely to require 3 instances
      // for seamless effect due to their height.
      if (elms[i].type === 'stamp') {
        // normal stamp
        if (!elms[i].mold.newStamp) {
          var frontDouble = new Stamp(elms[i].mold, ctx, elms[i].str, elms[i].yD, elms[i].props, elms[i].pos);
          var backDouble = new Stamp(elms[i].mold, ctx, elms[i].str, elms[i].yD, elms[i].props, elms[i].pos);
        } else {
          var frontDouble = elms[i].mold.newStamp(ctx, elms[i].str, elms[i].yD, elms[i].props, { x: elms[i].x, y: elms[i].y, z: elms[i].z });
          var backDouble = elms[i].mold.newStamp(ctx, elms[i].str, elms[i].yD, elms[i].props, { x: elms[i].x, y: elms[i].y, z: elms[i].z });
        }

      } else {
        // text
        var frontDouble = new TextStamp(elms[i].str, ctx, elms[i].yD, elms[i].props, elms[i].color);
        var backDouble = new TextStamp(elms[i].str, ctx, elms[i].yD, elms[i].props, elms[i].color);
      }
      // updated later in the draw loop, just make it out of view for now
      frontDouble.sprite.position.y = backDouble.sprite.position.y = -self.length;

      // add frist double
      duplicates.push(frontDouble);
      duplicates.push(backDouble);
    }
    return duplicates;
  };



  // CRUD ===================================================

  self.addDuplicates = function(newElms) {
    self.ctl.addElmsToStage(newElms);
    self.duplicates = self.duplicates.concat(newElms);
  };


  self.setPreviewDuplicates = function(newElms, ctx) {
    // remove old ones
    self.ctl.removeElmsFromStage(self.previewDuplicates);
    // calc the real duplicates
    var duplicates = self.calcDuplicates(newElms, ctx);
    // add new ones
    self.ctl.addElmsToStage(duplicates);
    // set current
    self.previewDuplicates = duplicates;
  };



  self.resetDuplcates = function(newElms) {
    // remove old ones from stage
    self.ctl.removeElmsFromStage(self.duplicates);
    // add new ones to stage
    self.ctl.addElmsToStage(newElms);
    // reset prop
    self.duplicates = newElms;
  };



  // TIME ===================================================

  self.offset = function() {
    // truncate. we only need px values
    return self.tick | 0;
  };


  self.tickFrame = function() {
    if (self.tick > self.length) { self.tick = 0; }
    var time = new Date().getTime();
    if (time + 60 >= self.lastTickTime) {
      // incremeent
      self.tick = self.tick + self.speed * (time - self.lastTickTime);
      // set last
      self.lastTickTime = time;
    }
  };

  self.yD = function() {
    return self.offset();
  };


});

module.exports = Sequencer;
