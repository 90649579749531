var blendModes = require('./settings').blendModes;
var PIXI = window.PIXI;

function TextStamp(str, ctx, yD, props, color, pos) {
  var self = this;

  self.type = 'text-stamp';
  self.str = str;
  self.color = color;
  self.yD = yD;                                                                 // original position for sequencer
  self.yCurrent = 0;                                                            // refreshed by sequencer
  self.sprite = new PIXI.Text(str, { font:"50px 'Erica One', cursive", fill: color || "#000"});      // Sprite object rendered by PIXI
  self.scale = 1;

  // properties
  self.props = props || {};

  self.calcPosition(ctx, pos);

}

TextStamp.prototype.calcPosition = function(ctx, pos) {
  var self = this;

  if (pos) {

    self.x = pos.x;
    self.y = pos.y;
    self.z = pos.z;
  } else {

    self.x = (ctx.width/2) - (self.sprite.width/2);
    self.y = (ctx.height/2) - (self.sprite.height/3);
    self.z = 1;
  }

  self.yCurrent = self.yD - self.yD*self.z;
};

TextStamp.prototype.export = function() {
  var self = this;

  return {
    type: self.type,
    str: self.str,
    scale: self.scale,
    yD: self.yD,
    yCurrent: self.yCurrent,
    color: self.color,
    pos: {
      x: self.x,
      y: self.y,
      z: self.z
    },
    props: self.props
  }

};
module.exports = TextStamp;
