module.exports = {

  title: 'Junkyard',
  index: 8,
  tempo: 140,
  meter: 6,
  collection: 'junkyard',
  timestamps: [

    // first drop
    {
      song: 8,
      timestamp: '00.40.929',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale', 'rotate-d'],
      }
    },

    // Verse 2
    {
      song: 8,
      timestamp: '01.01.498',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate-d'],
      }
    },

    // "oo ah"
    {
      song: 8,
      timestamp: '01.22.338',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate-d'],
      }
    },

    // "oo ah"
    {
      song: 8,
      timestamp: '01.52.927',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale', 'rotate-d'],
      }
    },

  ]

};
