module.exports = {

  title: 'Ranges',
  index: 3,
  tempo: 156,
  meter: 4,
  collection: 'ranges',
  timestamps: [


    // first kick, "fade away"
    { song: 3,
      timestamp: '00.50.916',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale'],
      }
    },
    // beat drops
    {
      song: 3,
      timestamp: '01.27.814',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate']
      }
    },

    // bg vocals in last part
    {
      song: 1,
      timestamp: '02.07.813',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate']
      }
    },
    // out
    { song: 1,
      timestamp: '02.32.449',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale']
      }
    },

  ]

};
