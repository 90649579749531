module.exports = [
	{
		file: "01.mp3",
		title: "Pearly Whites",
		number: "1"
	},
	{
		file: "02.mp3",
		title: "Three Stones",
		number: "2"
	},
	{
		file: "03.mp3",
		title: "Ballad",
		number: "3"
	},
	{
		file: "04.mp3",
		title: "Ranges",
		number: "4"
	},
	{
		file: "05.mp3",
		title: "Sleeper",
		number: "5"
	},
	{
		file: "06.mp3",
		title: "Siren",
		number: "6"
	},
	{
		file: "07.mp3",
		title: "Siren End",
		number: "7"
	},
	{
		file: "08.mp3",
		title: "It Won't Change Now",
		number: "8"
	},
	{
		file: "09.mp3",
		title: "Junkyard",
		number: "9"
	},
	{
		file: "10.mp3",
		title: "Vessels",
		number: "10"
	}
];
