var Collection = require('../postcard/Collection');

module.exports = {

  title: 'Pearly Whites',
  index: 0,
  tempo: 155,
  meter: 4,
  collection: 'sleeper',
  timestamps: [
    {
      song: 0,
      timestamp: '00.37.171',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale', 'rotate-d'],
      }
    },
    {
      song: 0,
      timestamp: '01.01.990',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale', 'rotate-d']
      }
    }
  ]

};
