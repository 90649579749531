module.exports = {

  title: 'Siren End',
  index: 6,
  tempo: 140,
  meter: 4,
  collection: 'siren',
  timestamps: [
    // first drop
    {
      song: 6,
      timestamp: '00.44.677',
      actions: ['fx'],
      data: {
        toggle: true,
        fxAdd: ['scale'],
      }
    },
    // "we can't leave now"
    {
      song: 6,
      timestamp: '01.18.946',
      actions: ['fx'],
      data: {
        fxAdd: ['rotate']
      }
    },

    // guitar solo
    {
      song: 6,
      timestamp: '01.53.290',
      actions: ['fx'],
      data: {
        fxRemove: ['rotate']
      }
    },

    // last bar of solo
    {
      song: 6,
      timestamp: '02.20.684',
      actions: ['fx'],
      data: {
        toggle: false,
        fxRemove: ['scale']
      }
    }
  ]

};
