/*

	IN THIS MODULE
	==============

	soundcloud.js - acts as the getter of soundcloud
		streams and the player of actual sound.

	player.js - controls the player elements in the DOM
		and handles their click events.


	~! SUPER COOL ACTIONS YOU CAN TRIGGER FROM ANYWHERE !~

	NOTE: these actions are meant to be deterministic
		so you ~SHOULD~ be able to call them from anywhere
		in the app and they'll work (I hope). They don't
		take any arguments.

	player:toggle
		play or pause the sound
	player:next
		go to and play the next song
		(loads the song if it needs to)
	player:previous
		go to and play the previous song
		(loads the song if it needs to)

	Try calling these from the console:
		EventDispatcherSingleton.emit('player:toggle');
		EventDispatcherSingleton.emit('player:next');
		EventDispatcherSingleton.emit('player:previous');

	~! SUPER COOL INFO THAT GETS TOSSED AROUND !~

	player:ready -> undefined
		fired after startup, indicating that the player
		has everything it needs to play the first song
	player:track -> Object
		fired when the track changes, indicating
		which track it is (for displaying title, etc)
	player:state -> Boolean
		describes whether the player is now playing or paused
	player:loading -> Boolean
		describes whether the player is now loading or not
	player:finished -> undefined
		called when the final song finishes

*/

var webaudio = require('./webaudio');
var structure = require('./structure');
var player = require('./player');
var Module = require('../eventDispatcher/Module');


webaudio.progress.onValue(function(data) {
	player.progress(data[1]);
});

module.exports = {
	volume: webaudio.volume,
	progress: webaudio.progress,
	structure: structure
};

// =============================================
// JUST A FUN TEST
// =============================================

var $input = $('#pc-input');


// here's raw volume output. it's a little crazy.

// webaudio.volume.onValue(function(vol) {
// 	$input[0].style.opacity = Math.min(1, 1 - (vol * 2));
// });


// you can smooth out the results by asking for
// a sliding window and averaging the last few frames.
// the more frames you average, though, the more it
// will appear to lag behind the music. 2 or 3 has
// worked well for me.

// webaudio.volume
// 	.slidingWindow(2,2)
// 	.map(function(values) {
// 		return values.reduce(function(a,b) { return a+b; }) / values.length;
// 	})
// 	.onValue(function(vol) {
// 		$input[0].style.opacity = Math.min(1, 1 - (vol * 2));
// 	});


// =============================================
// HOW STRUCTURE WORKS
// =============================================

// Just listen to the structure stream and read
// which action gets popped off. You can put whatever
// you want in those song structure objects and
// it will get passed through; I added a `song` field
// so we'd know which song is being played.



var m = new Module( function() {});
structure.onValue(function(event) {
	if (!event) return;
	m.emit('player:action', event);
	// if (event.song === 0 && event.actions.indexOf('color') > -1) {
	// 	$input[0].style.color = '#'+Math.floor(Math.random()*16777215).toString(16);
	// }
});

// if you want to see what gets fired, uncomment this line:
structure.log();
