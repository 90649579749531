var Bacon = require('../../vendor/Bacon');
var Module = require('../eventDispatcher/Module');
var Collection = require('./Collection');
var PostcardController = require('./PostcardController');
var SongController = require('./SongController');
var PostcardUI = require('./PostcardUI');
var PostcardSession = require('./PostcardSession');
var Intro = require('./intro');
var InputFeedback = require('./InputFeedback');

var player = require('../player');


var PostcardApp = new Module( function() {

  var self = this;


  // var c1 = new Collection('c1'),
  var $input  = $('#pc-input'),
      lineEnd = false,
      firstTime = true,
      firstLoad = false;


  self.ctl = PostcardController;
  self.ui = PostcardUI;
  self.fb = InputFeedback;
  self.ui.$input = $input;
  self.ui.ctl = self.ctl;
  self.playing = false;


  function onKeyUp(e) {
      if (!firstLoad) {
        return $input.val("");
      }

      if (firstTime) {
        // INIT ====================
        // - start the first track when user starts typing
        if ($input.val().length > 0) {
          if (!self.playing) {
            self.emit('player:toggle');
          }
          self.emit('firstKey');
          firstTime = false;
        }

      }
      // enter key
      // - adds the String to the Poscart Controler
      // - resets input to empty
      if (e.keyCode == 13) {
          // self.ctl.updatePreviewString(previewSring);
          self.ctl.addString($input.val());
          $input.val("");
      } else {
        // preview mode
        if (!lineEnd) {
          self.ctl.updatePreviewString($input.val());
        } else {
          lineEnd = false;
        }
      }
  };

  // set loading
  self.emit('collection:loaded', false);

  self.on('collection:loaded', function(loaded) {
    if (loaded && !firstLoad) {
      firstLoad = true;
    }
  });



  // INPUT setup =========================================

  $input.focus();

  var inputKeyUp = $input.asEventStream('keyup');
  inputKeyUp.onValue(onKeyUp);


  // RAF setup ============================================

  // raf bus
  var frames = new Bacon.Bus();

  function raf() {
    requestAnimationFrame(raf);
    frames.push();
  }
  raf();

  frames.onValue( function() {
      redrawCanvas();
  });

  function redrawCanvas() {
      self.ctl.draw();
  }

  // loading

  // PLAYER ===============================
  self.on('player:state', function(playing) {
    self.playing = playing;
  });


  // SONG =================================================
  self.initSongController = function() {
    // Song Controller receives events for song
    self.SongController = SongController;
    self.SongController.setCtl(self.ctl);
  };

  self.initSongController();

  // INIT ==================================================

  self.emit('postcard-app:init');

  // SAVED SESSION ========================================


});
